import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ElementModel, PageModel } from '@nimles/models';

const flatten = (elements: ElementModel[]): ElementModel[] => {
  if (!elements) {
    return [];
  }
  return [
    ...elements,
    ...elements.reduce(
      (list, element) =>
        element.children ? [...list, ...flatten(element.children)] : list,
      []
    ),
  ];
};

const Text = styled.div<{ center: boolean; maxWidth: string }>`
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  max-width: ${({ maxWidth }) => maxWidth ?? 'auto'};
`;

interface Props {
  page: PageModel;
  uniqueName: string;
  center?: boolean;
  maxWidth?: string;
}

export const PageText: FC<Props> = ({
  children,
  page,
  uniqueName,
  center,
  maxWidth,
}) => {
  if (!page || !uniqueName) {
    return null;
  }
  const elements = flatten(page.elements);
  const element = elements.find((e) => e.uniqueName === uniqueName);

  return element ? (
    element.type === 'Html' ? (
      <Text
        center={center}
        maxWidth={maxWidth}
        dangerouslySetInnerHTML={{ __html: element.content }}
      />
    ) : (
      <>{element.content}</>
    )
  ) : children ? (
    <Text center={center} maxWidth={maxWidth}>
      {children}
    </Text>
  ) : null;
};
