import React from 'react';
import { useTheme } from '@emotion/react';
import { Theme } from '@nimles/react-web-components';

export const BannerBottom = () => {
  const theme = useTheme<Theme>();
  return (
    <svg
      version="1.1"
      id="Lager_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1920 37"
      xmlSpace="preserve"
      style={{ marginBottom: '-12px' }}
    >
      <path
        fill={theme.colors.background.color}
        className="st0"
        d="M1920,0.1V36H0V0.1c241.8,16.3,582.5,26.4,960,26.4S1678.2,16.3,1920,0.1z"
      />
    </svg>
  );
};
