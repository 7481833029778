import React, { FC } from 'react';
import { Container, Section } from '@nimles/react-web-components';
import { BannerBottom } from '../svg/BannerBottom';
import styled from '@emotion/styled';

const StyledBanner = styled(Section)`
  margin-top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  transition: all 1s;
  min-height: 80px;
  text-align: center;
`;

const BannerContainer = styled.div`
  flex-direction: column;
  flex: 0 0 auto;
  transition: all 1s;
  min-height: 120px;
  justify-content: flex-end;
  background: linear-gradient(
    180deg,
    rgb(31, 91, 152) 0,
    rgba(31, 91, 152, 0.8) 50%,
    rgba(64, 143, 159, 0.7) 100%
  );
`;

const BannerContent = styled.div<{ large: boolean }>`
  padding-top: 100px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 120px;

  @media (min-width: ${({ theme }) => theme.thresholds.md}px) {
    min-height: ${({ large }) => (large ? '70vh' : 'auto')};
  }
`;

interface Props {
  round?: boolean;
  large?: boolean;
}

export const BannerSection: FC<Props> = ({
  children,
  round,
  large,
  ...props
}) => {
  return (
    <StyledBanner {...props}>
      <BannerContainer>
        <Container>
          <BannerContent large={large}>{children}</BannerContent>
        </Container>
        <BannerBottom />
      </BannerContainer>
    </StyledBanner>
  );
};
